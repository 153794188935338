import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Debug from '../src/Components/Debug/Debug';
import { CFormLabel, CFormSelect } from '@coreui/react';
import { Col } from 'react-bootstrap';

const AirlineOption = ({ option, handleChange, showSelects = false }) => {
  const departureDatetimes = [];
  const returnDatetimes = [];

  option.datetimes.forEach((dateTime) => {
    dateTime.date = new Date(dateTime.date);

    if (dateTime.type === 'return') {
      returnDatetimes.push(dateTime);
      return;
    }
    departureDatetimes.push(dateTime);
  });

  const [airLinesSelected, setAirLinesSelected] = useState({
    airline_departure_time: "",
    airline_return_time: "",
  });

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setAirLinesSelected({
      ...airLinesSelected,
      [name]: value,
    });

    handleChange(event);
  };

  const AirlineHoursSelects = () => {
    if (!showSelects) {
      return <>
          {departureDatetimes.length > 0 && departureDatetimes.map((datetime, index) => {
             return (<div key={index}>
               <strong>IDA:</strong>{' '}
               
               {datetime.date.toLocaleString('pt-br').split(', ').shift()}
               {' '}
               {datetime.date.toLocaleTimeString([], {
                 hour: '2-digit',
                 minute: '2-digit',
                 hour12: false,
               })}
 
               {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}` }
               <br />
             </div>);
           })}
 
           {returnDatetimes.length > 0 && <br />}
 
           {returnDatetimes.length > 0 && returnDatetimes.map((datetime, index) => {
             return (<div key={index}>
               <strong>VOLTA:</strong>{' '}
               
               {datetime.date.toLocaleString('pt-br').split(', ').shift()}
               {' '}
               {datetime.date.toLocaleTimeString([], {
                 hour: '2-digit',
                 minute: '2-digit',
                 hour12: false,
               })}
               {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}` }
               <br />
             </div>);
           })}
      </>
    }

    return <>
        {departureDatetimes.length > 0 && <Col xs={12}>
            <CFormLabel htmlFor="airline_departure_time">
              <strong>Horário de IDA</strong>
            </CFormLabel>
            <CFormSelect
                value={airLinesSelected.airline_departure_time || ""}
                onChange={handleSelectChange}
                id='airline_departure_time'
                name='airline_departure_time'
                required={true}
            >
              <option value="">Selecione o horário de IDA</option>
              {departureDatetimes.map((datetime, index) => {
                return (
                  <option key={index} value={datetime.id}>              
                    {datetime.date.toLocaleString('pt-br').split(', ').shift()}
                    {' '}
                    {datetime.date.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
      
                    {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}` }
                  </option>
                )
              })}
            </CFormSelect>
        </Col>}

        {returnDatetimes.length > 0 && (
          <Col xs={12}>
            <CFormLabel htmlFor="airline_return_time">
              <strong>Horário de VOLTA</strong>
            </CFormLabel>
            <CFormSelect
              value={airLinesSelected.airline_return_time || ""}
              onChange={handleSelectChange}
              id="airline_return_time"
              name="airline_return_time"
              required={true}
            >
              <option value="">Selecione o horário de VOLTA</option>
              {returnDatetimes.map((datetime, index) => (
                <option key={index} value={datetime.id}>
                  {datetime.date.toLocaleString('pt-br').split(', ').shift()}{" "}
                  {datetime.date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                  {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}`}
                </option>
              ))}
            </CFormSelect>
          </Col>
        )}
    </>
  };

  return <>
    <div className='my-10 flex'>
      <div className='w-[50%] text-white py-12 px-10 text-right inline text-lg' style={{backgroundColor: "#56575B", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", }}>
        Horários de voos <strong>{option.airline.name}</strong>
      </div>
      <div className='w-[50%] text-[#56575B] inline px-10 py-2 border border-[#56575B]' style={{backgroundColor: "#FFFFFF", borderTopRightRadius: "12px", borderBottomRightRadius: "12px", }}>
        <div>
          <AirlineHoursSelects />
        </div>
      </div>
    </div>
  </>
};

AirlineOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default AirlineOption;
