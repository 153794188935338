import axios from "axios";
import Isotope from "isotope-layout";
import Swal from "sweetalert2";
import Swiper from "swiper"

export const getCookie = (name) => {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
}

export const getParents = (elem) => {
    var parents = [];
    while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
        elem = elem.parentNode;
        parents.push(elem);
    }
    return parents;
}

export const addZeroBeforeNumber = (number) => {
    return (number > 9) ? number : `0${number}`
}

export const initializeSwiper = (item, params) => {
    const swiper_el = new Swiper(item, params);
    swiper_el.init();
    return swiper_el;
}

export const initializeIsotop = (item) => {
    let filter_menu = item.closest(".grid-wrapper").querySelector(".filter-menu");

    const grid = new Isotope(item, {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        percentPosition: true,
        stagger: 0,
        masonry: {
            columnWidth: '.grid-sizer',
        }
    })

    setTimeout(() => {
        grid.arrange({ filter: `*` })
    }, 1000);

    filter_menu && filter_menu.querySelectorAll("li").forEach((el) => {
        el.querySelector("span").addEventListener("click", function (e) {
            let element = e.target,
                filtered_text = element.getAttribute("data-filter");

            filter_menu.querySelectorAll("li").forEach((elm) => elm.classList.remove("active"))
            element.closest("li").classList.add("active");
            grid.arrange({ filter: filtered_text === "*" ? "*" : `.${filtered_text}` })
        })
    })

    return grid
}

export const InputField = (value) => {

    document.querySelectorAll(".quantity").forEach(item => {
        let num = value ? value : 0,
            el = item.querySelector(".qty-text"),
            plusBtn = item.querySelector(".qty-plus"),
            minusBtn = item.querySelector(".qty-minus");

        plusBtn.addEventListener("click", () => {
            num = num + 1;
            el.setAttribute("value", num);
        });

        minusBtn.addEventListener("click", () => {
            num = num > 0 ? num - 1 : 0;
            el.setAttribute("value", num)
        });

        el.setAttribute("value", num);
    })
}

export const sendNewsletter = async (data) => {
    try {
        const req = await fetch(`${process.env.REACT_APP_API_URL}/frontend/newsletter/subscribe`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });

        if (!req.ok) {
            const errorResponse = await req.json();
            throw new Error(errorResponse.message || 'Erro na requisição.');
        }

        const response = await req.json();
        Swal.fire({
            title: "Newsletter cadastrada com sucesso! Obrigado por se cadastrar no nosso newsletter!",
            icon: 'success',
            customClass: {
                confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                container: "tracking-wider",
                popup: "tracking-wider",
                header: "tracking-wider",
                title: "tracking-wider",
            },
            buttonsStyling: false,
            confirmButtonText: 'OK',
        });
        return response;
    } catch (error) {
        Swal.fire({
            title: error.message,
            icon: 'error',
            customClass: {
                confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                container: "tracking-wider",
                popup: "tracking-wider",
                header: "tracking-wider",
                title: "tracking-wider",
            },
            buttonsStyling: false,
            confirmButtonText: 'OK',
        });
    }
}

export const isValidCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

export const isValidCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs inválidos conhecidos
    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) {
        return false;
    }

    return true;
};

export const resetForm = (actions, recaptcha) => {
    if (actions) {
        actions.resetForm()
        actions.setStatus(true)
        actions.setSubmitting(false);
        setTimeout(() => actions.setStatus(false), 5000)
    }

    if (recaptcha) {
        recaptcha.current.reset();
        recaptcha.current.captcha.classList.remove("error");
    }
}

// Header Menu Position
export const SetHeaderMenuPos = () => {
    
    function setMegaMenuPos() {
        let menu = document.querySelectorAll(".megamenu");
        if (menu.length > 0) {
            
            menu.forEach(item => {
                let menuWidth = item.clientWidth,
                    menuLeftPos = item.getBoundingClientRect().left;

                if (menuLeftPos + menuWidth >= window.screen.width) {
                    item.style.left = `-${(menuLeftPos + menuWidth) - window.innerWidth + 25}px`;
                }
            })
        }
    }

    function setSimpleMenuPos() {
        let menu = document.querySelectorAll(".simple-dropdown-menu");
        
        if (typeof (menu) != 'undefined' && menu != null) {
            menu.forEach(item => {
                let pos = item.getBoundingClientRect().left,
                    posTop = item.getBoundingClientRect().top,
                    width = item.clientWidth,
                    height = item.clientHeight;

                if (pos + width + 10 > window.innerWidth) {
                    item.style.left = `${window.innerWidth - (pos + width + 10)}px`;
                    item.classList.add("menu-left");
                }

                if ((posTop + height) > window.innerHeight) {
                    item.style.transform = `translateY(${-(posTop + height - window.innerHeight)}px)`;
                }
            })
        }
    }

    setMegaMenuPos();
    setSimpleMenuPos();

    window.addEventListener('resize', (event) => {
        setTimeout(() => {
            setMegaMenuPos();
            setSimpleMenuPos();
        }, 500);
    });
}

export const setDocumentFullHeight = () => {
    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--viewport-height', `${window.innerHeight}px`)
    }

    window.addEventListener("resize", documentHeight)
    documentHeight()
}

export const getAllConfigurations = async () => {
    const cacheKey = 'configurations';
    const cacheTimestampKey = 'configurationsTimestamp';

    // Verifica se os dados estão em cache e se o cache ainda é válido (1 hora)
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

    if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp, 10) < 3600000) {
        return JSON.parse(cachedData);
    }

    // Se o cache estiver inválido, faz a solicitação ao servidor usando Axios
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/frontend/configurations/all`);
        const data = response.data;

        // Atualiza o cache com os novos dados e o carimbo de data/hora
        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheTimestampKey, Date.now().toString());

        return data;
    } catch (error) {
        console.error('Erro ao obter configurações:', error);
        throw error;
    }
};

export const getCurrentEvent = async () => {
    const cacheKey = 'currentEvent';
    const cacheTimestampKey = 'currentEventTimestamp';

    // Verifica se os dados estão em cache e se o cache ainda é válido (1 hora)
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

    if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp, 10) < 3600000) {
        return JSON.parse(cachedData);
    }

    // Se o cache estiver inválido, faz a solicitação ao servidor usando Axios
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/frontend/events/current`);
        const data = response.data;

        // Atualiza o cache com os novos dados e o carimbo de data/hora
        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheTimestampKey, Date.now().toString());

        return data;
    } catch (error) {
        console.error('Erro ao obter o evento atual', error);
        throw error;
    }
};

export const getTopImage = (topImageConfigSlug, mobileTopImageConfigSlug, setConfigurations = () => {}, setCustomStyles = () => {}) => {
    getAllConfigurations()
        .then(data => {
            setConfigurations(data)
            
            let imageUrl = data[topImageConfigSlug]['value_translated']
            
            if (
                window.innerWidth < 768 
                && data[mobileTopImageConfigSlug]
                && data[mobileTopImageConfigSlug]['value_translated']
            ) {
                imageUrl = data[mobileTopImageConfigSlug]['value_translated']
            }

            const topImageUrl = `${process.env.REACT_APP_API_URL}${imageUrl}`

            setCustomStyles({
                backgroundImage: `url(${topImageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            })
        });
}