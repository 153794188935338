import HeaderData from "./HeaderData";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Navbar } from "react-bootstrap";
import { getAllConfigurations, getCurrentEvent } from "../../Functions/Utilities";
import Header, { HeaderNav, Menu } from "./Header";
import HeaderMenu from "./HeaderMenu";
import SocialIcons from "../SocialIcon/SocialIcons";
import SocialIconsData from "./SocialIconsData";

const FrontendHeader = props => {
  const [configurations, setConfigurations] = useState(null);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllConfigurations();

        let headerDataToShow = HeaderData;

        if (data && data['telefones']) {

          if (!headerDataToShow.some(item => 'Telefones' === item.title)) {
            headerDataToShow.push({
              title: 'Telefones',
              dropdown: Object.entries(data['telefones']['value_translated']).map(([key, value]) => {
                return { title: `${key}: ${value}`, link: `tel: ${value}`};
              })
            });
          }
        }

        const currentEvent = await getCurrentEvent();

        if (currentEvent?.id) {
          headerDataToShow.forEach((item, idx) => {
            if (!item.dropdown) {
              return;
            }

            for (let idx2 in item.dropdown) {
              let currentLink = item.dropdown[idx2];

              if (currentLink.title === "Passagens Aéreas") {
                headerDataToShow[idx].dropdown[idx2].link = `/frontend/event/${currentEvent.id}`
              }

              if (currentLink.title === "Hospedagem") {
                headerDataToShow[idx].dropdown[idx2].link = `/frontend/hotels/${currentEvent.id}/${currentEvent.city?.id}`
              }
            }
          });
        }

        setHeaderData(headerDataToShow);

        setConfigurations(data);
      } catch (error) {
        console.error('Erro ao obter configurações:', error);
      }
    };

    fetchData();
  }, []);

  return <>
    {/* Header Start */}
    <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
      <HeaderNav theme="dark" containerClass="!px-0" className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0">
        <Col lg={6} xs={"auto"} className="px-lg-0 position-absolute left-0 right-0 mx-lg-auto text-center md:!relative mr-auto">
          <Link aria-label="header logo" className="inline-block relative z-10" to="/">
            <Navbar.Brand className="inline-block p-0 align-middle text-center mx-auto">
              { 
                configurations && configurations['logo-principal-claro']
                  ? <img className="default-logo" width="111" height="36" loading="lazy" src={process.env.REACT_APP_API_URL + configurations['logo-principal-claro']['value_translated']} data-rjs={configurations['logo-principal-claro']['value_translated']} alt='logo' />
                  : null
              }
              { 
                configurations && configurations['logo-principal-escuro']
                  ? <img className="alt-logo" width="111" height="36" loading="lazy" src={process.env.REACT_APP_API_URL + configurations['logo-principal-escuro']['value_translated']} data-rjs={process.env.REACT_APP_API_URL + configurations['logo-principal-claro']['value_translated']} alt='logo' />
                  : null
              }
              { 
                configurations && configurations['logo-principal-escuro']
                  ? <img className="mobile-logo" width="111" height="36" loading="lazy" src={process.env.REACT_APP_API_URL + configurations['logo-principal-escuro']['value_translated']} data-rjs={process.env.REACT_APP_API_URL + configurations['logo-principal-claro']['value_translated']} alt='logo' />
                  : null
              }  
            </Navbar.Brand>
          </Link>
        </Col>
        <Col className="md:flex md:justify-end md:px-[15px]">
          <Navbar.Toggle className="order-last md:ml-[17px] mr-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <div className="hidden md:block">
            <Navbar.Collapse className="col-auto justify-center">
              <Menu {...props} />
              <SocialIcons data={SocialIconsData}></SocialIcons>
            </Navbar.Collapse>
          </div>

          <HeaderMenu headerData={headerData}/>
        </Col>
      </HeaderNav>
    </Header>
    {/* Header End */}
  </>
};

export default FrontendHeader;