import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Row } from 'react-bootstrap';
import { BrazilianFormatData } from '../../../components/BrazilianFormatData';

const SelectHotels = (props) => {
    const [hotels, setHotels] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventBannerStyle, setEventBannerStyle] = useState({
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    });

    const { cityId, eventId } = useParams();

    useEffect(() => {
        try {
            axios
                // .get(`${process.env.REACT_APP_API_URL}/frontend/hotels?city_id=${cityId}&promotion_id=0`)
                .get(`${process.env.REACT_APP_API_URL}/frontend/hotels/event/${eventId}`)
                .then((response) => {
                    let data = response.data.data;
                    
                    setHotels(data);
                })
                .catch((error) => {
                    console.error('Erro ao buscar pacotes:', error);
                });

            axios.get(`${process.env.REACT_APP_API_URL}/frontend/events/${eventId}`)
                .then(response => {
                    setEventData(response.data.data);

                    let imgPath = response.data?.data?.photos[0]?.path;

                    if (imgPath) {
                        imgPath = imgPath.includes('/storage')
                            ? `${process.env.REACT_APP_API_URL}/${imgPath}`
                            : `${process.env.REACT_APP_API_URL}/storage/${imgPath}`
                    }

                    const bgColor = response.data?.data?.photos[0]?.pivot?.color || "#ffffff";

                    setEventBannerStyle({
                        ...eventBannerStyle,
                        background: `url('${imgPath}') center center / contain no-repeat, ${bgColor}`,
                    });
                });
        } catch (error) {
            console.error('Erro ao obter configurações:', error);
        }
    }, []);


    return <>
        <div className="bg-white overflow-hidden">
            {/* Banner de topo */}
            <section className="overflow-hidden full-screen h-[50vh]" style={eventBannerStyle}></section>
            {/* Banner de topo */}

            {/* Informações do evento */}
            <section>
                <Row>
                    <Col xs={12} className='text-[#0068D9] text-center my-10 uppercase tracking-wider'>
                        Evento
                    </Col>
                    <Col xs={12} className='text-black text-center my-2 uppercase tracking-wider text-4xl'>
                        {eventData?.name}
                    </Col>

                    <Col lg={4} md={1}></Col>
                    <Col lg={4} md={10} className='text-black text-center my-2 tracking-wide text-xl'>
                        <BrazilianFormatData date={eventData.date_start} showTime={false} />
                        -&nbsp;
                        <BrazilianFormatData date={eventData.date_end} showTime={false} />
                    </Col>
                    <Col lg={4} md={1}></Col>

                    {/* <Col xs={4}></Col>
                    <Col xs={4} className='text-black text-center my-2 tracking-wider text-xl' dangerouslySetInnerHTML={{__html: package_?.highlights?.split("\\r").join("").split("\\n").join("<br>")}}>
                    </Col>
                    <Col xs={4}></Col> */}

                </Row>
            </section>
            {/* Informações do evento */}

            {/* Opções de pacote */}
            <section>                 
                {hotels.map((hotel) => {
                    const { photos, promotion } = hotel;

                    return <Row className="my-14">
                        <Col sm={1} xs={0}></Col>
                        <Col sm={10} xs={12}>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    {photos?.length ? (
                                        <img
                                            src={photos[0].base64encoded}
                                            style={{ width: "100%" }}
                                            alt=""
                                            className="mb-4 sm:mb-0"
                                        />
                                    ) : null}
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className="text-center sm:text-left">
                                        <div>
                                            {Array.from({ length: hotel.stars }).map((_, index) => (
                                                <span key={index}>&#11088;</span>
                                            ))}
                                        </div>
                                        <div className="text-black font-bold tracking-wider text-2xl">
                                            {hotel.name}
                                        </div>
                                        <div className="text-black font-light tracking-wider text-xl">
                                            {hotel.address}
                                        </div>
                                        <div
                                            className="text-black font-bold tracking-wider text-lg"
                                            dangerouslySetInnerHTML={{
                                                __html: promotion?.name
                                                    ?.split("\\r")
                                                    .join("")
                                                    .split("\\n")
                                                    .join("<br>"),
                                            }}
                                        ></div>
                                        <div className="my-10">
                                            <Button
                                                aria-label="link"
                                                className="btn-fill w-[90%] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] sm:!px-[42px] text-sm shadow-none !rounded-[50px]"
                                                href={`#/frontend/reservation-form?hotel=${hotel.id}&event=${eventId}`}
                                            >
                                                Reservar
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1} xs={0}></Col>
                    </Row>
                
                })}

                    {/* <Col xs={2}></Col>
                    <Col xs={8}>
                        <AirlineOption option={currentOption} />
                    </Col>
                    <Col xs={2}></Col> */}
            </section>
            {/* Opções de pacote */}

        </div>
    </>
};

export default SelectHotels;