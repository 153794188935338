import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Debug from "../Components/Debug/Debug";
import axios from "axios";
import { BrazilianFormatData } from "../../../components/BrazilianFormatData";

const ReservationConfirmation = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const reservationHash = params.get('hash');

    const [reservation, setReservation] = useState({});
    const [event, setEvent] = useState({});

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(window.location.href).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };

    const [eventBannerStyle, setEventBannerStyle] = useState({
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    });

    useEffect(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/frontend/reservation/show-by-hash/${reservationHash}`);

            const { reservation } = response.data.data;

            setEvent(reservation.event);

            setReservation(reservation);

            const eventData = reservation.event;

            let imgPath = eventData.photos[0]?.path;

            if (imgPath) {
                imgPath = imgPath.includes('/storage')
                    ? `${process.env.REACT_APP_API_URL}/${imgPath}`
                    : `${process.env.REACT_APP_API_URL}/storage/${imgPath}`
            }

            const bgColor = eventData.photos[0]?.pivot?.color || "#ffffff";

            setEventBannerStyle({
                ...eventBannerStyle,
                background: `url('${imgPath}') center center / contain no-repeat, ${bgColor}`,
            });

        } catch (error) {
            window.location.href = `${window.location.origin}/#/frontend`;
        }
    }, []);

    return (
        <>
            <div className="bg-white overflow-hidden">
                {/* Banner de topo */}
                <section className="overflow-hidden full-screen h-[50vh]" style={eventBannerStyle}></section>
                {/* Banner de topo */}

                <section className="overflow-hidden">
                    <Row className="mt-[70px]">
                        <Col xs={1} lg={2}></Col>
                        <Col xs={10} lg={8}>
                            <div className="border rounded-[12px] p-5 mb-10 text-center">
                                <h2 className="font-bold text-center">
                                    Reserva número {reservation.reservation_number} efetuada com sucesso!
                                </h2>
                                {reservation.hash_expires_at && (
                                    <p className="text-red-600 text-center mt-2">
                                        Para a segurança de seus dados de reserva, essa página de confirmação estará disponível somente até <BrazilianFormatData date={reservation.hash_expires_at} />
                                    </p>
                                )}

                                <button
                                    onClick={copyToClipboard}
                                    className="btn-fill md:w-full xs:mt-8 sm:mt-8 !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] lg:!px-[32px] sm:!px-[22px] text-sm shadow-none !rounded-[50px] btn"
                                >
                                    Clique aqui para copiar seu link de confirmação
                                </button>
                                {copied && <span className="ml-2 text-green-500">Copiado!</span>}

                            </div>

                            <Row className="border rounded-[12px] mb-10">
                            <Col xs={12} className='bg-[#0068D9] text-white text-center uppercase tracking-wider font-bold py-10 rounded-[12px] text-2xl'>
                                Detalhes da Reserva
                            </Col>

                            { reservation.departure_date && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                    <strong>Partida:</strong> <BrazilianFormatData date={reservation.departure_date} hour={reservation.departure_time} />
                                </Col>
                            )}

                            { reservation.departure_date && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                    <strong>Retorno:</strong> <BrazilianFormatData date={reservation.return_date} hour={reservation.return_time} />
                                </Col>
                            )}

                            { reservation.checkin_date && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                    <strong>Checkin:</strong> <BrazilianFormatData date={reservation.checkin_date} showTime={false} />
                                </Col>
                            )}

                            { reservation.checkout_date && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                    <strong>Checkout:</strong> <BrazilianFormatData date={reservation.checkin_date} showTime={false} />
                                </Col>
                            )}

                            {reservation.city_departure?.name && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                <strong>Cidade de Partida:</strong> <span>{reservation.city_departure.name}</span>
                                </Col>
                            )}
                            {reservation.event?.name && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                <strong>Evento:</strong> <span>{reservation.event.name}</span>
                                </Col>
                            )}
                            {reservation.airline?.name && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                <strong>Companhia Aérea:</strong> <span>{reservation.airline.name}</span>
                                </Col>
                            )}
                            {reservation.hotel?.name && (
                                <Col xs={12} xl={6} lg={6} md={6} className="p-3">
                                <strong>Hotel:</strong> <span>{reservation.hotel.name}</span>
                                </Col>
                            )}
                            </Row>

                            {reservation.people?.length > 0 && (
                            <Row className="border rounded-[12px] mb-10">
                                <Col xs={12} className='bg-[#0068D9] text-white text-center uppercase tracking-wider font-bold py-10 rounded-[12px] text-2xl'>
                                Hóspedes {reservation.people.some(person => person.typeName === 'Passageiro') ? "e Passageiros" : ""} 
                                </Col>
                                {reservation.people.map((person, index) => (
                                    <Col xs={12} xl={6} lg={6} md={6} className="p-3" key={index}>
                                        <strong>Nome:</strong> <span>{person.name}</span>
                                        <br />
                                        <strong>Tipo:</strong> <span>{person.typeName}</span>
                                        {person.birthday && (
                                        <>
                                            <br />
                                            <strong>Data de Nascimento:</strong> <BrazilianFormatData date={person.birthday} showTime={false} />
                                        </>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            )}
                        </Col>
                    </Row>
                </section>
            </div>
        </>
    );
};

export default ReservationConfirmation;
